<template>
  <div class="category-view">
    <div class="row">

    </div>
    <div class="full-width flex-row-mobile">
      <div class="col-3">
          <div class="row" style="display:flex;justify-content:space-between;">
              <div class="single-filter">
                  <a @click="changeCat(0)">TUTTI</a>
              </div>
              <div class="single-filter">
                  <a @click="changeCat(32)">SCELTE</a>
              </div>
              <div class="single-filter">
                  <a @click="changeCat(44)">VITA</a>
              </div>
              <div class="single-filter">
                  <a @click="changeCat(33)">CUCINA</a>
              </div>
          </div>
      </div>
      <div class="col-8" style="display:flex;justify-content:flex-end;">
        <div class="num-prodotti neue">{{items.length}} articol{{items.length > 1 ? 'i' : 'o'}}</div>
      </div>
    </div>
    <div class="full-width">
      <div class="product-container">
        <ArticleThumb
          v-show="k < currentIndex"
          :column="'col-' + (12 / cols)"
          v-for="(item, k) in items"
          v-bind:key="k"
          :postId="item.id"
          :post="item" />

        <div class="flex-center">
          <div class="button" @click="moreIndex" v-if="currentIndex < items.length">Mostra altri articoli</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ArticleThumb from '@/components/ArticleThumb.vue'
  import axios from 'axios'
  import paginaZuccheriPostdata from '@/mockups/paginaZuccheriPostdata.js'

  export default {
    name: 'BlogCategory',

    components: {
      ArticleThumb
    },

    data() {
      return {
        items: [],
        menuOpen: false,
        cat: {},
        category: 0,
        secondLevel: [],
        thirdLevel: {},
        currentIndex: 9
      }
    },

    async created(){
      await axios.get('https://altromercato.it/wp-json/wp/v2/posts?_embed&per_page=100&categories=32,33,44')
        .then((res) => this.items = [paginaZuccheriPostdata, ...res.data])
          .then(() => console.log(this.items))
    },

    watch: {
      category : {
        immediate: true,
        async handler () {
          if(this.category > 0){
            await axios.get('https://altromercato.it/wp-json/wp/v2/posts?_embed&per_page=100&categories=' + this.category)
              .then((res) => this.items = this.category == 32 ? [paginaZuccheriPostdata, ...res.data] : res.data)
                .then(() => console.log(this.items))
          } else {
            await axios.get('https://altromercato.it/wp-json/wp/v2/posts?_embed&per_page=100&categories=32,33,44')
              .then((res) => this.items = [paginaZuccheriPostdata, ...res.data])
                .then(() => console.log(this.items))
          }
        }
      }
    },
    methods: {
      moreIndex(){
        this.currentIndex = this.currentIndex + this.currentIndex
      },
      changeCat(id){
        this.category = id
      }
    },
    props: {
      slug: String,
      code: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      },
      cols: {
        type: Number,
        default: 3
      },
      isBrand: {
        type: Boolean,
        default: false
      },
    }
  }
</script>

<style scoped>
  .category-view .full-width {
    justify-content: space-between;
  }
  .ordina, .num-prodotti {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 26px;
  }

  .ordina {
    font-weight: 500;
    cursor: pointer;
  }

  .ordina span {
    margin-left: 4px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 680px) {
    .single-filter {
        margin: 0 9px 0 0;
    }

    .full-width.flex-row-mobile {
      padding: 0 10px;
      flex-direction: row;
    }

    .product-container {
      padding: 10px;
    }
  }
</style>
