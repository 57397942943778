<template>
  <div :class="column + ' product-outer'">
    <router-link :to="post.isPaginaZuccheri ? `/zuccheri/mascobado` : `/post/${post.id}/${post.slug}`" :class="'product-card ' + (border ? 'border' : '')" v-if="post.id">

      <div>
        <!--<img :src="post.postInfos.thumbnail" class="product-image" v-if="post.postInfos.thumbnail" />-->
        <img v-if="post._embedded['wp:featuredmedia']" :src="post._embedded['wp:featuredmedia']['0'].source_url" class="product-image" />
      </div>

      <div class="product-content">
        <center>
          <a href="#"><h2 v-html="post.title.rendered"></h2></a>
        </center>

        <div class="row">
          <div class="col-12 align-center">
            <router-link v-if="post.isPaginaZuccheri" :to="`/zuccheri/mascobado`" class="add-to-cart">Leggi di più</router-link>
            <router-link v-else :to="`/post/${post.id}/${post.slug}`" class="add-to-cart">Leggi di più</router-link>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
  //import utils from '@/utils.js'

  export default {
    name: 'ArticleThumb',
    props: ['column', 'border', 'postID', 'post'],
    components: {

    },
    data() {
      return {
        item: {},
      }
    },

    mounted () {
      //this.item = post
    },

    methods: {
    }
  }
</script>

<style scoped>
  .product-outer {
    padding: 0 5px;
  }

  .product-card {
    display: block;
    width: 100%;
    min-height: 442px;
    padding: 20px 0;
    background-color: #fff;
    position: relative;
    margin-bottom: 25px;
  }

  .product-card .promo {
    position: absolute;
    top: 16px;
    right: 0;
    padding: 0 6px;
    height: 22px;
    line-height: 24px;
    color: #fff;
    background-color: #f09049;
    font-weight: 800;
    font-size: 14px;
  }

  .product-image {
    width: 235px;
    height: 235px;
    object-fit: cover;
    display: block;
    margin: 0 auto 48px auto;
  }

  .marks {
    position: absolute;
    top: 16px;
    left: 10px;
    width: 32px;
  }

  .marks img {
    width: 32px;
    height: auto;
    margin-bottom: 3px;
  }

  .product-content {
    padding: 0 22px;
  }

  .product-content h2 {
    font-size: 17px;
    line-height: 130%;
    font-weight: 700;
    font-family: neue-haas-grotesk-display, sans-serif;
    margin: 10px 0 0 0;
    min-height: 66px;
  }

  .product-card .discount {
    position: relative;
    left: -10px;
    padding: 0 6px;
    height: 22px;
    line-height: 22px;
    color: #fff;
    background-color: #f09049;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    font-family: neue-haas-grotesk-display, sans-serif;
    margin: 5px 0;
  }

  .product-card .discount .old-price {
    text-decoration: line-through;
    display: inline-block;
    margin-right: 6px;
  }

  .product-card .price .current {
    display: block;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    line-height: 100%;
  }

  .product-card .price .online-price {
    display: block;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    letter-spacing: 0.4px;
  }

  .add-to-cart {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 6px 12px;
    border-radius: 16px;
    color: #fff;
    background-color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: neue-haas-grotesk-display, sans-serif;
    letter-spacing: 0.2px;
    text-align: center;
    max-width: 120px;
    cursor: pointer;
    margin: 0 auto;
  }

  .add-to-cart span {
    margin-left: 7px;
    width: 27px;
  }

  .border {
    border: 1px solid #eee !important;
  }

  .hover-img .back {
    display: none;
  }

  .hover-img:hover .back {
    display: block;
  }

  .hover-img:hover .front {
    display: none;
  }

  @media (max-width: 680px) {
    .product-outer {
      padding: 0;
    }
  }
</style>
