import img from '@/assets/paginaZuccheri/blog.jpg'

export default {
	id: 1,
	slug: "",
	_embedded: {
		'wp:featuredmedia': {
			'0': {
				source_url: img
			}
		}
	},
	title: {
		rendered: "Buoni in tutti i sensi: esplora la guida sensoriale ai nostri zuccheri"
	},
	categories: [],
	isPaginaZuccheri: true
}